import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { EditResponse, EditResult } from "@/api/info-authcode/response";
import { EditRequest } from "@/api/info-authcode/request";
import * as InfoAuthcodeAPI from "@/api/info-authcode";
import { isSuccess } from "@/api/response";

const MODULE_NAME = "info-authcode/edit";

/**
 * 認証コード編集情報取得（/detail-auth-code）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Edit extends VuexModule {
  // state
  editResponse: EditResponse = {} as EditResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.editResponse);
  }

  get getMessage() {
    return this.editResponse.message;
  }

  get getResult() {
    if (this.editResponse.results) {
      return this.editResponse.results;
    }
    return {} as EditResult;
  }

  // MutationActions
  @MutationAction
  async edit(editRequest: EditRequest) {
    const editResponse = await InfoAuthcodeAPI.edit(editRequest);
    return {
      editResponse
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      editResponse: {} as EditResponse
    };
  }
}

export default getModule(Edit);
