import service from "@/api/service";
import { SearchRequest } from "@/api/authcode/request";
import { RegisterRequest } from "@/api/authcode/request";
import { SearchResponse } from "@/api/authcode/response";
import { CommonResponse } from "@/api/response";

/**
 * 認証コード一覧・検索APIをコールします。
 *
 * @param searchRequest 認証コード検索のリクエストボディ
 * @return SearchResponse
 */
export async function search(searchRequest: SearchRequest) {
  const response = await service.post("/search-auth-code", searchRequest);
  return response.data as SearchResponse;
}

/**
 * 認証コード登録更新APIをコールします。
 *
 * @param registerRequest 認証コード登録更新のリクエストボディ
 * @return RegisterRequest
 */
export async function register(registerRequest: RegisterRequest) {
  const response = await service.post("/regist-auth-code", registerRequest);
  return response.data as CommonResponse;
}
