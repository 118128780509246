import service from "@/api/service";
import { EditRequest } from "@/api/info-authcode/request";
import { EditResponse } from "@/api/info-authcode/response";

/**
 * 認証コード情報詳細取得APIをコールします。
 *
 * @param editRequest 認証コード情報詳細取得のリクエストボディ
 * @return EditResponse
 */
export async function edit(editRequest: EditRequest) {
  const response = await service.post("/detail-auth-code", editRequest);
  return response.data as EditResponse;
}
