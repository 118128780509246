import {
  getModule,
  Module,
  VuexModule,
  MutationAction
} from "vuex-module-decorators";
import store from "@/store";
import { CommonResponse } from "@/api/response";
import { RegisterRequest } from "@/api/authcode/request";
import * as AuthcodeAPI from "@/api/authcode";
import { isSuccess } from "@/api/response";

const MODULE_NAME = "authcode/register";

/**
 * 認証コード登録更新API（/regist-authcode）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Register extends VuexModule {
  // state
  registerResponse: CommonResponse = {} as CommonResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.registerResponse);
  }

  get getMessage() {
    return this.registerResponse.message;
  }

  // MutationActions
  @MutationAction
  async register(registerRequest: RegisterRequest) {
    const registerResponse = await AuthcodeAPI.register(registerRequest);
    return {
      registerResponse
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      registerResponse: {} as CommonResponse
    };
  }
}

export default getModule(Register);
